
























































































































































































































































































































































































































































































import {
    Component,
    Vue,
	Watch
} from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { getNameByEmail } from '@/utils/users';
import { DateTime } from 'luxon';
import {
	getComponent,
    addNumberPadding,
    sleep,
    dateDiff,
    getOffset,
    getElementPosition,
    getElementCenterPosition,
} from '@/utils/helpers'
import {
    SCHEDULE_COMPONENT_COLORS,
    SCHEDULE_DATE_TITLE_HEIGHT,
    SCHEDULE_CHART_DAY_WIDTH,
    SCHEDULE_ROW_HEIGHT,
    SCHEDULE_PIXELS_TO_CURRENT_DAY,
	SCHEDULE_ROW_STACKED_HEIGHT,
} from '@/lib/constants'

import workspaceModule from '@/store/modules/workspaceModule';
import schedule from '@/router/routes/schedule';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
		ComponentDetails: () => getComponent('schedule/ComponentDetails'),
		AddJob: () => getComponent('schedule/AddJob'),
		AddField: () => getComponent('schedule/AddField'),
		EditScheduleGroup: () => getComponent('schedule/EditScheduleGroup'),
		WorkflowAutomationDialog: () => getComponent('tasqs/WorkflowAutomationDialog'),
		Multiselect,
    },
})

export default class ScheduleV2 extends mixins() {

	reloadGanttChartKey = 0
	refreshComponentKey = 0

	workflowAutomationDialog = false

	showSaveSuccessfulNotification = false
	showSavingDetailsBannerID = false
	savingDetailsBannerIDText = ''

	// Loading views
    dataLoading = false
	loadingGanttChart = false
    isDeletingRowLoadingView = false
	dataLoadingDeleteRow = false
	savingChartComponentChange = false
	isDeletingFile = false
	isUpdatingScheduleView = false

	// Gantt chart data
    viewTotalDays = 30
	dates: any[] = []
    currentDate = ''
    rows: any[] = []
	showingRowEditMenu = null
	deleteRigRowConfirmShowing = null
	popupBackground = false
	addNewJobPopupInnerShowing = false

	scheduleComponentUserColors = {}
	chartStartDatetime
	rowIDs: string[] = []
	isResizingElement = false
	didDragElement = false
	filterScheduledRowComponents: any[] = []
	isEditingGroupRowVal = null
	existingTableID = ''
	addNewWorkspacePopupAddField = false
	templateDetails = [
		{
			type: "Fields",
			values: [
		
			]
		},{
			type: "Rules",
			values: [
				
			]
		}
	]


	didSelectViewType(view) {
		this.$emit('did-select-view', view);
	}


	get automationRuleCount() {
		if (workspaceModule.activeTable == null) {
			return 0
		}
		// @ts-ignore
		return workspaceModule.activeTable.Rules.length
	}

	get editGroupDetails() {
		return scheduleModule.editGroupDetails
	}

	get confirmFileDelete() {
		return scheduleModule.confirmDeleteFile
	}

	get expandedFile() {
		return scheduleModule.expandedFile
	}

	get scheduleColumnWidth() {
		return SCHEDULE_CHART_DAY_WIDTH
	}

	getScheduleRowHeight(row_index, total_rows) {
		return row_index == (total_rows - 1) ? SCHEDULE_ROW_HEIGHT : SCHEDULE_ROW_STACKED_HEIGHT
	}

	get scheduledRigComponents(): any[] {
		return scheduleModule.scheduledRigComponents;
	}

	get activeComponent() {
		return scheduleModule.activeComponent
	}

	get activeColumns() {
		return scheduleModule.activeComponentResponseColumns
	}

    get enabledWells() {
        return assetsModule.enabledWells;
    }

    get currentDay(): number {
        return new Date().getDate()
    }

    get scheduledRowComponents(): any[] {
        return scheduleModule.scheduledRowComponents
    }

	get availableUsers() {
		return accountModule.reassignmentList.map((i) => ({
			// @ts-ignore
			text: i.name,
			value: i.email,
		}));
	}


	async confirmDeleteFile() {
		this.isDeletingFile = true
		if (scheduleModule.selectedTabID == null) {
			this.cancelDeleteFile()
			return
		}

		var editingColumn: any = null

		for (var y = 0; y < scheduleModule.activeComponent.Columns.length; y++) {
			if (scheduleModule.activeComponent.Columns[y].CustomID == scheduleModule.selectedTabID) {
				editingColumn = y
			}
		}

		var updateColumn = JSON.parse(JSON.stringify(scheduleModule.activeComponent.Columns[editingColumn]))
		var spliceAtIndex: any = null


		for (var x = 0; x < updateColumn.Response.length; x++) {
			if (updateColumn.Response[x].FileName == this.confirmFileDelete.FileName) {
				spliceAtIndex = x
			}
		}

		updateColumn.Response.splice(spliceAtIndex, 1);
		
		scheduleModule.updateColumnByIndex({
			column_index: editingColumn, 
			column: updateColumn})
		await scheduleModule.updateScheduleItem({
			item_id: this.activeComponent.ID,
			columns: JSON.stringify(this.activeColumns)
		})

		this.isDeletingFile = false
		this.cancelDeleteFile()
		this.refreshComponentKey += 1
		// scheduleModule.setConfirmDeleteFile(null)

	}


	closeWorkflowAutomationDialog() {
		this.workflowAutomationDialog = false
	}


	updateComponentDetails() {
		this.refreshComponentKey += 1
	}

	closeEditGroupDetailsPopup() {
		this.popupBackground = false
		scheduleModule.setEditGroupDetails(null)
	}

	cancelDeleteFile() {
		scheduleModule.setConfirmDeleteFile(null)
	}


	closeExpandedFile() {
		scheduleModule.setExpandedFile(null)
	}

	calculateTotalRowHeight(row_count) {
		return ((SCHEDULE_ROW_HEIGHT - SCHEDULE_ROW_STACKED_HEIGHT) * (row_count - 1)) + SCHEDULE_ROW_HEIGHT
	}

	callGetNameByEmail(email) {
		return getNameByEmail(email)
	}


    async created() {
		this.loadingGanttChart = true
		scheduleModule.getColumnMappingData({})
		scheduleModule.resetScheduledRowComponents()
		accountModule.getReassignmentList()

		// await workspaceModule.getWorkspaces()
		this.initializePage()

    }

	initializePage() {
		scheduleModule.resetScheduledRowComponents()
		this.existingTableID = this.$route.params.table_id
		//  && workspaceModule.activeTables[0].Usage != "ASSETS"
		if (!this.$route.params.table_id && workspaceModule.activeTables.length > 0 && workspaceModule.activeTables[0].Usage != "ASSETS") {
			this.$router.push({
			name: 'Workspaces',
			params: {
				// @ts-ignore
				id: workspaceModule.activeWorkspace.ID,
				table_id: workspaceModule.activeTables[0].ID
			},
			});			
		}
		workspaceModule.setActiveTable({id: this.$route.params.table_id || ''});
		if (workspaceModule.activeTable == '' || workspaceModule.activeTable == undefined || workspaceModule.activeTable == null) {
			this.$router.push({
			name: 'Workspaces',
			params: {
				// @ts-ignore
				id: workspaceModule.activeWorkspace.ID
			},
			});	
			return
		}
        assetsModule.getEnabledWells();
        this.reloadPage()
	}

	async reloadPage() {
		this.loadingGanttChart = true
		this.dates = []

		this.currentDate = addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
		var start_datetime = new Date(new Date().setHours(new Date().getHours() - 24))
		start_datetime.setHours(0,0,0,0);
		this.chartStartDatetime = start_datetime
		var end_datetime = new Date(new Date().setHours(start_datetime.getHours() + (this.viewTotalDays * 24)))
		end_datetime.setHours(0,0,0,0);

		scheduleModule.resetScheduledRowComponents()
		await accountModule.getReassignmentList()

		await this.addDataToGanttChart()

		this.loadingGanttChart = false

	}



	async addDataToGanttChart() {
		
		this.currentDate = addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
		var start_datetime = new Date(new Date().setHours(new Date().getHours() - 96))
		start_datetime.setHours(0,0,0,0);
		this.chartStartDatetime = start_datetime
		var end_datetime = new Date(new Date().setHours(start_datetime.getHours() + (this.viewTotalDays * 24)))
		end_datetime.setHours(0,0,0,0);

		const startDateString = `${start_datetime.getUTCFullYear()}-${addNumberPadding(start_datetime.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(start_datetime.getUTCDate(), 2, '0')}T${addNumberPadding(start_datetime.getUTCHours(), 2, '0')}:${addNumberPadding(start_datetime.getUTCMinutes(), 2, '0')}:${addNumberPadding(start_datetime.getUTCSeconds(), 2, '0')}`;
		const endDateString = `${end_datetime.getUTCFullYear()}-${addNumberPadding(end_datetime.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(end_datetime.getUTCDate(), 2, '0')}T${addNumberPadding(end_datetime.getUTCHours(), 2, '0')}:${addNumberPadding(end_datetime.getUTCMinutes(), 2, '0')}:${addNumberPadding(end_datetime.getUTCSeconds(), 2, '0')}`;
		

		// @ts-ignore
		await scheduleModule.getScheduledJobsV2({ start_date: startDateString, end_date: endDateString, table_id: workspaceModule.activeTable.ID });
		this.filterScheduledRowComponents = []
		// await sleep(100)
		this.dates = []
		this.filterScheduledRowComponents = []
		// Show chart 30 days
		for (var days = 0; days < this.viewTotalDays; days++) {
			var day_datetime = new Date(start_datetime)
			day_datetime = new Date(day_datetime.setDate(start_datetime.getDate() + days))
			var hours_array: Date[] = []
			var day_object = {
				"day_id": day_datetime.toString(),
				"day_short": day_datetime.getDate(),
				"day": day_datetime,
				"hours": hours_array
			}
			for (var hours = 0; hours < 24; hours++) {
				var hour_datetime = new Date(day_datetime)
				hour_datetime = new Date(hour_datetime.setHours(day_datetime.getHours() + hours))
				day_object.hours.push(hour_datetime)
			}
			this.dates.push(day_object)
		}

		for (var x = 0; x < this.scheduledRowComponents.length; x++) {
			var rowElementID = this.scheduledRowComponents[x].title_row.selection_id.toLowerCase()
			rowElementID = rowElementID.replaceAll(" ", "")
			this.filterScheduledRowComponents.push(this.scheduledRowComponents[x])
		}

		this.reloadGanttChartKey += 1
	}


	getFullComponentDetailsByID(id) {
		if (workspaceModule.activeTable != null) {
			// @ts-ignore
			for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
				// @ts-ignore
				for (var y = 0; y < workspaceModule.activeTable.Groups[x].Items.length; y++) {
					// @ts-ignore
					if (id == workspaceModule.activeTable.Groups[x].Items[y].ID) {
						// @ts-ignore
						return workspaceModule.activeTable.Groups[x].Items[y]
					}
				}
			}
		}

	}


	getComponentStatus(component) {
		var detailedComponent = this.getFullComponentDetailsByID(component.component_id)
		if (detailedComponent != null) {
			var status = detailedComponent.Status

			if (detailedComponent.Status.constructor == Object) {
				status = detailedComponent.Status.Status
			}
			if (status == "New") {
				return ''
			} else if (status == "In Progress") {
				return ''
			} else if (status == "Waiting On") {
				return ' opacity: 0.5;'
			} else if (status == "Done") {
				return ' background: rgba(144,144,144,1);'
			}
			// return detailedComponent.Status
		}
		return ''
	}

	showComponentDetails(component) {
		scheduleModule.setActiveComponent(null)
		var detailedComponent = this.getFullComponentDetailsByID(component.component_id)
		if (detailedComponent != null) {
			scheduleModule.setActiveComponent(detailedComponent)
			for (var x = 0; x < detailedComponent.Columns.length; x++) {
				detailedComponent.Columns[x].ItemID = detailedComponent.ID
			}
			scheduleModule.setActiveComponentResponseColumns(detailedComponent.Columns)
		}
		this.refreshComponentKey += 1

	}
	

	showEditRowPopup(row) {
		this.popupBackground = true
		scheduleModule.setEditGroupDetails(row)

	}


	async didLoseFocusOnEditRowText(data, index) {
		var updated_val = this.$refs[data.title_row.selection_id][0].value
		if (updated_val != this.isEditingGroupRowVal) {
			await scheduleModule.updateGroupName({
				group_id: data.title_row.selection_id,
				group_name: updated_val
			})
			this.filterScheduledRowComponents[index].title_row.selection_name = updated_val 
			
			this.isEditingGroupRowVal = null
			this.showSaveSuccessfulNotification = true
			workspaceModule.getWorkspaces(null)
			await sleep(4000)
			this.showSaveSuccessfulNotification = false



			
		}
	}

	didFocusOnEditRowText(data) {
		this.isEditingGroupRowVal = this.$refs[data.title_row.selection_id][0].value
	}


	addNewJobClicked() {
		this.addNewJobPopupInnerShowing = true
		this.popupBackground = true

	}

	hideAddNewJobClicked() {
		this.addNewJobPopupInnerShowing = false
		this.popupBackground = false

	}


	toggleShowingRowEditMenu(selection_id) {
		if (this.showingRowEditMenu == selection_id) {
			this.showingRowEditMenu = null
		} else {
			this.showingRowEditMenu = selection_id
		}
	}


	resizeComponent(component_id, component) {
		let component_div = document.getElementById(component_id)!
		this.isResizingElement = true
		let m_pos;
		function resize(e) {
			const dx = (m_pos - e.x) * -1;
			m_pos = e.x;
			component_div.style.width = (parseInt(getComputedStyle(component_div, '').width) + dx) + "px";
		}
		document.addEventListener("mousemove", resize, false);
		document.addEventListener("mouseup", () => {
			document.removeEventListener("mousemove", resize, false);
			this.isResizingElement = false
			var roundedDayNumber = Math.round(parseInt(component_div.style.width) / SCHEDULE_CHART_DAY_WIDTH)
			component_div.style.width = (roundedDayNumber * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
			this.updateTestDurationHours(component, roundedDayNumber * 24)
		}, false);
	}


	getComponentLeftPosition(component) {
		let days_diff = dateDiff(this.chartStartDatetime, new Date(component.start_date))
		return (days_diff * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
	}

	getComponentWidth(component) {
		var width_days_diff = dateDiff(new Date(component.start_date), new Date(component.end_date)) + 1
		return (width_days_diff * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
	}



    async addNewRowClicked() {
		var group_names: any[] = []
		for (var x = 0; x < this.scheduledRowComponents.length; x++) {
			var selectionName = this.scheduledRowComponents[x].title_row.selection_name
			group_names.push(selectionName)
		}

		var group_name = ''
		var count = 1
		while (true) {
			if (!group_names.includes("Row " + count.toString())) {
				group_name = "Row " + count.toString()
				break
			}
			count += 1
		}

		// @ts-ignore
		this.loadingGanttChart = true
		await scheduleModule.postNewTableGroup({
			username: accountModule.user.email,
			// @ts-ignore
			workspace_id: workspaceModule.activeWorkspace.ID,
			// @ts-ignore
			table_id: workspaceModule.activeTable.ID,
			group_name: group_name,
			// @ts-ignore
			columns: JSON.stringify(workspaceModule.activeTable.Groups[0].Columns)
		})


		let data: any =  '';

         // @ts-ignore
		if(workspaceModule.activeTable && workspaceModule.activeTable.ID){
			// @ts-ignore
			data = workspaceModule.activeTable.ID
		}

		//  location.reload();
		// workspaceModule.getWorkspaces(null)
		await this.initializePage()
		// workspaceModule.getWorkspaces(null)
			// this.selectedWorkspace = workspace.ID
		setTimeout(() => {
					this.$router.push({
			name: 'Workspaces',
			params: {
				// @ts-ignore
				id: workspaceModule.activeWorkspace.ID,
				table_id: data || ''
			},
			});
			// @ts-ignore
			workspaceModule.setActiveTable({id: data || ''});
			this.loadingGanttChart = false
		}, 5000)
		
    }


    async confirmDeleteRow() {
		this.dataLoadingDeleteRow = true
		await scheduleModule.deleteGroup({
			// @ts-ignore
			group_id: this.deleteRigRowConfirmShowing.title_row.selection_id,
		})
		this.deleteRigRowConfirmShowing = null
        this.isDeletingRowLoadingView = false
		this.dataLoadingDeleteRow = false
        location.reload();
    }

	clostConfirmDeleteRigPopup() {
		this.deleteRigRowConfirmShowing = null
	}

	showDeleteRowPopup(row) {
		this.deleteRigRowConfirmShowing = row
	}

	// Pulled in from outside
	getComponentDateRangeFromChartLocation(component) {
		var target_element_pos = getElementPosition(component, "DRAGGED")
		var target_element_min_x = target_element_pos.x
		var target_element_max_x = target_element_pos.x + component.getBoundingClientRect().width

		var new_start_of_day = (Math.floor((target_element_min_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetime.getDate())
		var new_end_of_day = (Math.floor((target_element_max_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetime.getDate())
		var firstDayStart = new Date(this.chartStartDatetime.getFullYear(), this.chartStartDatetime.getMonth(), 1);
		var firstDayEnd = new Date(this.chartStartDatetime.getFullYear(), this.chartStartDatetime.getMonth(), 1);
		var new_start_date = firstDayStart.setDate((firstDayStart.getDate()) + new_start_of_day);
		var new_end_date = firstDayEnd.setDate((firstDayEnd.getDate() - 1) + new_end_of_day);
		return [new Date(new_start_date), new Date(new_end_date)]
	}

	getClosestRowIDOnDragEnd(element) {
		var elementCenter = getElementCenterPosition(element, "DRAGGED")
		var rowID = null
		for (var x = 0; x < this.filterScheduledRowComponents.length; x++) {
			let filterScheduledRowComponent = this.filterScheduledRowComponents[x]
			for (var y = 0; y < filterScheduledRowComponent.rows.length; y++) {
				let row = filterScheduledRowComponent.rows[y]
				// @ts-ignore
				rowID = y + '<>' + row.title_row.selection_id
				var rowElement = document.getElementById(rowID!) !
				var rowMinY = getElementCenterPosition(rowElement, "ROW").y - ((rowElement.getBoundingClientRect().height / 2) + 15)
				var rowMaxY = getElementCenterPosition(rowElement, "ROW").y + ((rowElement.getBoundingClientRect().height / 2) + 45)
				if (elementCenter.y > rowMinY && elementCenter.y < rowMaxY) {
					return rowID
				} else if (elementCenter.y <= rowMinY && x == 0) {
					return rowID
				} else if (elementCenter.y >= rowMaxY && x == (this.rowIDs.length - 1)) {
					return rowID
				}
			}
		}
		return rowID
	}

	getAllChartComponents(ignoringDraggedComponent: any = null) {
		var totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]')
		if (ignoringDraggedComponent != null) {
			var otherComponents: any[] = []
			for (var x = 0; x < totalComponentsList.length; x++) {

				if (ignoringDraggedComponent!.getAttribute('componentID') == totalComponentsList[x].getAttribute('componentID')) {
					continue
				}
				otherComponents.push(totalComponentsList[x])
			}
			return otherComponents
		}
		return totalComponentsList
	}


	getColorForComponent(component) {
		var color = "#51cea8"
		if (this.scheduleComponentUserColors[component.well] != null) {
			color = this.scheduleComponentUserColors[component.well]
		} else {
			color = SCHEDULE_COMPONENT_COLORS[Object.keys(this.scheduleComponentUserColors).length % SCHEDULE_COMPONENT_COLORS.length]
			this.scheduleComponentUserColors[component.well] = color
		}
		return color
	}



// ***********************************************
// ********** Update component params ************
// ***********************************************

	async updateTestDurationHours(component, hours) {
		this.addDataToGanttChart()
	}


	updateComponentRanges(componentsToUpdate) {
		this.isUpdatingScheduleView = true
		this.setSavingDetailsBannerID("Saving updated schedule")
		// document.getElementById("savingDetailsBannerID") !.style.display = "block"
		var promises: any[] = [];
		for (var w = 0; w < componentsToUpdate.length; w++) {
			var draggedComponentStartDate = componentsToUpdate[w].StartDate.toISOString().slice(0,10).replace(/-/g,"-");
			var draggedComponentEndDate = componentsToUpdate[w].EndDate.toISOString().slice(0,10).replace(/-/g,"-");

			// TASQ_DATE_RANGE
			var localComponent = this.getFullComponentDetailsByID(componentsToUpdate[w].PredictionID)
			if (!localComponent) {
				continue
			}
			for (var e = 0; e < localComponent.Columns.length; e++) {
				if (localComponent.Columns[e].ColumnType == "START_DATE") {
					localComponent.Columns[e].Response = draggedComponentStartDate
				}
			}
			for (var e = 0; e < localComponent.Columns.length; e++) {
				if (localComponent.Columns[e].ColumnType == "DUE_DATE") {
					localComponent.Columns[e].Response = draggedComponentEndDate
				}
			}
			promises.push(scheduleModule.updateScheduleItem({
				item_id: componentsToUpdate[w].PredictionID,
				group_id: componentsToUpdate[w].RowID.split("<>")[1],
				columns: JSON.stringify(localComponent.Columns)
			}))
		}

		Promise.all(promises).then(() => {
			this.savingChartComponentChange = false
			
			this.addDataToGanttAsync()
			
		}, function (err) {
			// error occurred
		});

	}

	async addDataToGanttAsync() {
		await this.addDataToGanttChart()
		this.isUpdatingScheduleView = false
		this.removeSavingDetailsBannerID(5000, "Your changes have been saved")
	}

	async updateSingleItemDateRange(start_date, end_date, item_id) {
		this.setSavingDetailsBannerID("Updating item date range")
			// TASQ_DATE_RANGE
			this.isUpdatingScheduleView = true
			var localComponent = this.getFullComponentDetailsByID(item_id)
			if (!localComponent) {
				return
			}
			for (var e = 0; e < localComponent.Columns.length; e++) {
				if (localComponent.Columns[e].ColumnType == "TASQ_DATE_RANGE") {
					localComponent.Columns[e].Response[0] = start_date
					localComponent.Columns[e].Response[1] = end_date
				}
			}

		await scheduleModule.updateScheduleItem({
			item_id: item_id,
			columns: JSON.stringify(localComponent.Columns)
		})
		

		this.savingChartComponentChange = false
		
		await this.addDataToGanttChart()
		this.isUpdatingScheduleView = false
		this.removeSavingDetailsBannerID(5000, "Your changes have been saved")

	}

	setSavingDetailsBannerID(text) {
		this.savingChartComponentChange = true
		this.showSavingDetailsBannerID = true
		this.setSavingDetailsBannerIDText(text)
	}

	setSavingDetailsBannerIDText(text) {
		this.savingDetailsBannerIDText = text
	}

	async removeSavingDetailsBannerID(wait = 5000, text = '') {
		this.savingChartComponentChange = false
		if (text != '') {
			this.setSavingDetailsBannerIDText(text)
		}
		await sleep(wait)
		this.showSavingDetailsBannerID = false
		this.savingDetailsBannerIDText = ''
	}


/* 
   ***********************************************
   ********* Handle dragged components ***********
   ***********************************************
*/

	pos1 = 0
	pos2 = 0
	pos3 = 0
	pos4 = 0
	draggedElement

	startDrag(e, component) {
		this.dragMouseDown(e)
	}


	dragMouseDown(e) {
		e = e || window.event;
		e.preventDefault();
		var target_element = e.target
		if (!e.target.hasAttribute('isDraggableComponent')) {
			target_element = e.target.parentElement
		}
		this.draggedElement = target_element
		// get the mouse cursor position at startup:
		target_element.style.zIndex = "99999"
		var components = this.getAllChartComponents(target_element)

		for (var x = 0; x < components.length; x++) {
			components[x].style.zIndex = "999"
			components[x].style.setProperty("z-index", "999", "important");
		}

		this.pos3 = e.clientX;
		this.pos4 = e.clientY;
		document.onmouseup = this.closeDragElement;
		// call a function whenever the cursor moves:
		document.onmousemove = this.elementDrag;
	}

	elementDrag(e) {
		if (this.isResizingElement) {
			return
		}
		e = e || window.event;
		e.preventDefault();
		// calculate the new cursor position:
		this.pos1 = this.pos3 - e.clientX;
		this.pos2 = this.pos4 - e.clientY;
		this.pos3 = e.clientX;
		this.pos4 = e.clientY;
		// set the element's new position:
		this.draggedElement.style.top = (this.draggedElement.offsetTop - this.pos2) + "px";
		this.draggedElement.style.left = (this.draggedElement.offsetLeft - this.pos1) + "px";
		this.didDragElement = true
	}

	closeDragElement(e) {
		/* stop moving when mouse button is released:*/
		document.onmouseup = null;
		document.onmousemove = null;
		if (!this.didDragElement) {
			return
		}
		var components = this.getAllChartComponents(e.target)

		for (var x = 0; x < components.length; x++) {
			components[x].style.zIndex = "99999"
		}
		this.didDragElement = false
		this.handleDraggedComponent(e)

	}


	snapToClosestDayXValue(target_element) {
		var target_element_pos = getElementPosition(target_element, "DRAGGED")
		var target_element_min_x = target_element_pos.x
		var closest_day = (Math.floor(((target_element_min_x - (SCHEDULE_CHART_DAY_WIDTH / 1.5)) - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetime.getDate()) + 1
		target_element.style.left = ((closest_day * SCHEDULE_CHART_DAY_WIDTH) - ((this.chartStartDatetime.getDate() - 1) * SCHEDULE_CHART_DAY_WIDTH)) + "px"
	}

	snapDraggedElementToRow(target_element, draggedElementNewRowID) {
		var componentRowID = draggedElementNewRowID
		target_element.style.top = "10px"
		document.getElementById(componentRowID)!.appendChild(target_element)
		this.snapToClosestDayXValue(target_element)

	}

	handleDraggedComponent(e) {

		var target_element = e.target
		if (!e.target.hasAttribute('isDraggableComponent')) {
			target_element = e.target.parentElement
		}
		if (!target_element.hasAttribute('isDraggableComponent')) {
			target_element = target_element.parentElement
		}

		var draggedElementNewRowID = this.getClosestRowIDOnDragEnd(target_element)
		var otherComponents: any[] = []
		var totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]')
		for (var x = 0; x < totalComponentsList.length; x++) {
			if (target_element.getAttribute('componentID') == totalComponentsList[x].getAttribute('componentID')) {
				continue
			}
			if (draggedElementNewRowID == this.getClosestRowIDOnDragEnd(totalComponentsList[x])) {
				otherComponents.push(totalComponentsList[x])
			}
		}

		this.snapDraggedElementToRow(target_element, draggedElementNewRowID)
		var componentsToUpdate: any[] = []
		var newTargetElementDateRange = this.getComponentDateRangeFromChartLocation(target_element)
		componentsToUpdate.push({
			"PredictionID": target_element.getAttribute('componentID'),
			"StartDate": newTargetElementDateRange[0],
			"EndDate": newTargetElementDateRange[1],
			"RowID": draggedElementNewRowID
		})
		if (otherComponents.length > 0) {

			// Overlaps and preceeding
			var overlap_proceeding: any[] = []
			var non_overlap_proceeding: any[] = []
			var overlap_following: any[] = []
			var non_overlap_following: any[] = []

			var target_element_pos = getElementPosition(target_element, "DRAGGED")
			var target_element_center_x = getElementCenterPosition(target_element, "DRAGGED").x
			var target_element_max_x = target_element_pos.x + target_element.getBoundingClientRect().width
			var target_element_min_x = target_element_pos.x
			for (var y = 0; y < otherComponents.length; y++) {
				var other_component_pos = getElementPosition(otherComponents[y], "OTHER")
				var other_element_center_x = getElementCenterPosition(otherComponents[y], "OTHER").x
				var other_element_max_x = other_component_pos.x + otherComponents[y].getBoundingClientRect().width
				var other_element_min_x = other_component_pos.x

				if (other_element_center_x < target_element_center_x && other_element_max_x > target_element_min_x) {
					overlap_proceeding.push(otherComponents[y])
				} else if (other_element_center_x > target_element_center_x && other_element_min_x < target_element_max_x) {
					overlap_following.push(otherComponents[y])
				} else if (other_element_center_x < target_element_center_x) {
					non_overlap_proceeding.push(otherComponents[y])
				} else if (other_element_center_x > target_element_center_x) {
					non_overlap_following.push(otherComponents[y])
				} else if (other_element_min_x < target_element_max_x && other_element_max_x > target_element_min_x) {
					overlap_following.push(otherComponents[y])
				}
			}
		}
		this.updateComponentRanges(componentsToUpdate)
	}


	@Watch('$route.params.table_id', {
		immediate: true,
	})
	async onTasqRouteIdChange(id: string) {
		if (this.existingTableID != id && id != null && id != undefined) {
			this.existingTableID = id
		this.initializePage()
		}
	}
}


